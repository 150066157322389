// src/components/OnePost.js

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import sanityClient from '../../client.js'
import Nav from '../Nav'
import BlockContent from '@sanity/block-content-to-react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function Writing() {
  const navigate = useNavigate()
  const [writingData, setWritingData] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
                title,
                slug,
                "images": imagesGallery[]{
                  _key,
                    "url": asset->.url
                  },
                description,
                linkTitle,
                link,
                body,
                tags
              }`
      )
      .then((data) => setWritingData(data[0]))
      .catch(console.error)
  }, [slug])

  if (!writingData) return <div className="loading">Loading...</div>

  return (
    <div className="post">
      <Nav />
      <div className="post-inner">
        <div className="writing-background">
          <img src="https://april-lin.vercel.app/backgrounds/modes/writing/Vector6.png" alt="" />
        </div>
        <button className="field" onClick={() => navigate(-1)}>
          ←
        </button>
        <div className="writing-fields">
          <div className="field">
            <h2 className="writing-title titles">{writingData.title}</h2>
          </div>
          <img className="join writing-squiggle" src="https://april-lin.vercel.app/joins/modes/writing/Vector14.png" alt="" />
          {writingData.images && (
            <div className="field">
              <div className="post-image image">
                <Carousel>
                  {writingData.images.map((image) => (
                    <img key={image._key} src={image.url} alt="" className="inner-slide-img" />
                  ))}
                </Carousel>
              </div>
            </div>
          )}
          <div className="field">
            <a href={writingData.link}>{writingData.linkTitle}</a>
          </div>
          <div className="field writing-description">
            <BlockContent blocks={writingData.description} projectId="vy80zibe" dataset="production" />
          </div>
        </div>
        <div className="post-body">
          {/* <img className="body-background" src="https://april-lin.vercel.app/borders/modes/writing/Vector34.png" alt=""/> */}
          <div className="inner-body">
            <BlockContent blocks={writingData.body} projectId="vy80zibe" dataset="production" />
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../../client.js'
import Nav from '../Nav'

export default function Writing() {
  const [writingData, setWriting] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "writing"] | order(order){
            title, 
            slug, 
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`
      )
      .then((data) => setWriting(data))
      .catch(console.error)
  }, [])

  return (
    <main>
      <Nav />
      <section>
        <div>
          {' '}
          {writingData &&
            writingData.map((writing, index) => (
              <article className="inner-nav-item">
                <Link className="titles writing-title" to={'/modes/writing/' + writing.slug.current} key={writing.slug.current}>
                  <span key={index}>
                    <h2>{writing.title}</h2>
                  </span>
                </Link>
              </article>
            ))}
        </div>
      </section>
    </main>
  )
}

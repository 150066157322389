import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'
import imageUrlBuilder from '@sanity/image-url'
import Nav from './Nav'

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
  return builder.image(source)
}

export default function Introductions() {
  const [introductionsData, setIntroductionsData] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "introductions"]{
            title, 
            headShot{
                asset->{
                    _id,
                    url
                },
                alt
            },
            image1{
                asset->{
                    _id,
                    url
                },
                alt
            },
            image2{
                asset->{
                    _id,
                    url
                },
                alt
            },
            name,
            cv,
            awards,
            press,
            bio,
            contact,
            bookshelf
        }`
      )
      .then((data) => setIntroductionsData(data[0]))
      .catch(console.error)
  }, [])

  if (!introductionsData) return <div className="loading">Loading...</div>

  return (
    <main>
      <Nav />
      <section>
        {/* <h3>{introductionsData.title}</h3> */}
        <div className="intropage">
          <div className="bio-block">
            <div className="intro-block">
              <img className="join bio-squiggle" src="https://april-lin.vercel.app/joins/introductions/Vector13.png" alt="" />
              <div className="intro-title bio-title titles">Bio</div>
              <div className="inner-intro-block bio">
                <img className="border" src="https://april-lin.vercel.app/borders/introductions/Vector68.png" alt="" />
                <div className="block">
                  <BlockContent blocks={introductionsData.bio} projectId="vy80zibe" dataset="production" />
                </div>
              </div>
            </div>
          </div>

          <div className="intro-block cv">
            <a className="intro-title titles" href={introductionsData.cv} rel="noopener noreferrer" target="_blank">
              CV
            </a>
          </div>

          <div className="intro-flex">
            <div className="intro-block">
              <img className="join press-squiggle" src="https://april-lin.vercel.app/joins/introductions/Vector74.png" alt="" />
              <div className="intro-title titles">Press</div>
              <div className="inner-intro-block press">
                <img className="border" src="https://april-lin.vercel.app/borders/introductions/Vector73.png" alt="" />
                <div className="block">
                  <BlockContent blocks={introductionsData.press} projectId="vy80zibe" dataset="production" />
                </div>
              </div>
            </div>

            <div className="intro-pics intro-pic-1">
              <img src={urlFor(introductionsData.headShot).url()} alt={introductionsData.name} />
            </div>
            <div className="intro-pics intro-pic-2">
              <img src={urlFor(introductionsData.image1).url()} alt={introductionsData.name} />
            </div>
            {introductionsData.image2 && (
              <div className="intro-pics intro-pic-3">
                <img src={urlFor(introductionsData.image2).url()} alt={introductionsData.name} />
              </div>
            )}

            <div className="intro-block">
              <img className="join awards-squiggle" src="https://april-lin.vercel.app/joins/introductions/Vector75.png" alt="" />
              <div className="intro-title titles">Awards</div>
              <div className="inner-intro-block awards">
                <img className="border" src="https://april-lin.vercel.app/borders/introductions/Vector69.png" alt="" />
                <div className="block">
                  <BlockContent blocks={introductionsData.awards} projectId="vy80zibe" dataset="production" />
                </div>
              </div>
            </div>
          </div>

          <div className="intro-flex-2">
            <div className="intro-block">
              <img className="join contact-squiggle" src="https://april-lin.vercel.app/joins/introductions/Vector76.png" alt="" />
              <div className="intro-title titles">Get in touch!</div>
              <div className="inner-intro-block contact">
                <img className="border" src="https://april-lin.vercel.app/borders/introductions/Vector71.png" alt="" />
                <div className="block">
                  <BlockContent blocks={introductionsData.contact} projectId="vy80zibe" dataset="production" />
                </div>
              </div>
            </div>

            <div className="intro-block">
              <img className="join bookshelf-squiggle" src="https://april-lin.vercel.app/joins/introductions/Vector77.png" alt="" />
              <div className="intro-title titles">Bookshelf</div>
              <div className="inner-intro-block bookshelf">
                <img className="border" src="https://april-lin.vercel.app/borders/introductions/Vector78.png" alt="" />
                <div className="block">
                  <BlockContent blocks={introductionsData.bookshelf} projectId="vy80zibe" dataset="production" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

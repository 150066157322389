// this talks to sanity

import sanityClient from "@sanity/client";

export default sanityClient({
    projectId: "vy80zibe",
    dataset: "production",
    useCdn: true,
    apiVersion: '2022-09-25',
});

import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import imageUrlBuilder from '@sanity/image-url'
import HomeNav from './HomeNav'

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
  return builder.image(source)
}

export default function Home() {
  const [homeData, setHomeData] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"]{ 
            title,
            homeImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`
      )
      .then((data) => setHomeData(data[0]))
      .catch(console.error)
  }, [])

  if (!homeData) return <div className="loading">Loading...</div>

  return (
    <main>
      <HomeNav />
      <section>
        <div>
          <div className="home-image">
            <img src={urlFor(homeData.homeImage).url()} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
}

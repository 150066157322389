// src/components/OnePost.js

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import sanityClient from '../../client.js'
import Nav from '../Nav'
import BlockContent from '@sanity/block-content-to-react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function Curating() {
  const navigate = useNavigate()
  const [curatingData, setCuratingData] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
                title,
                slug,
                "images": imagesGallery[]{
                  _key,
                    "url": asset->.url
                  },
                date,
                linkTitle,
                link,
                body
              }`
      )
      .then((data) => setCuratingData(data[0]))
      .catch(console.error)
  }, [slug])

  if (!curatingData) return <div className="loading">Loading...</div>

  return (
    <div className="post">
      <Nav />
      <div className="post-inner">
        <div className="moving-background-post">
          <img src="https://april-lin.vercel.app/backgrounds/facilitations/Vector6(Stroke).png" alt="" />
        </div>
        <button className="field" onClick={() => navigate(-1)}>
          ←
        </button>
        <div className="performance-fields">
          <div className="field">
            <h2 className="facilitations-title titles">{curatingData.title}</h2>
          </div>
          <div div className="field">
            {curatingData.date}
          </div>
          <div className="field">
            <a href={curatingData.link}>{curatingData.linkTitle}</a>
          </div>
          <div className="field">
            <BlockContent blocks={curatingData.body} projectId="vy80zibe" dataset="production" />
          </div>
          {curatingData.images && (
            <div className="field">
              <div className="facilitations-image image inner-post">
                <Carousel>
                  {curatingData.images.map((image) => (
                    <img key={image._key} src={image.url} alt="" className="inner-slide-img" />
                  ))}
                </Carousel>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

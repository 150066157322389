// src/components/OnePost.js

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'
import Nav from './Nav'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function MovingImage() {
  const navigate = useNavigate()
  const [movingImageData, setMovingImageData] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
                title,
                slug,
                "images": imagesGallery[]{
                  _key,
                    "url": asset->.url
                  },
                date,
                link,
                body,
                description,
                year
              }`
      )
      .then((data) => setMovingImageData(data[0]))
      .catch(console.error)
  }, [slug])

  if (!movingImageData) return <div className="loading">Loading...</div>

  return (
    <div className="post">
      <Nav />
      <div className="post-inner">
        <div className="moving-background-post">
          <img src="https://april-lin.vercel.app/backgrounds/moving-image/Vector6(Stroke).png" alt="" />
        </div>
        <button className="field" onClick={() => navigate(-1)}>
          ←
        </button>
        <div>
          <div className="field year">{movingImageData.date},</div>
          <div className="field year">{movingImageData.year}</div>
        </div>
        <h2 className="moving-title titles">{movingImageData.title}</h2>
        <div className="field">
          <a href={movingImageData.link}>Link</a>
        </div>
        <div className="field">
          <BlockContent blocks={movingImageData.description} projectId="vy80zibe" dataset="production" />
        </div>
        <div className="field">
          <BlockContent blocks={movingImageData.body} projectId="vy80zibe" dataset="production" />
        </div>
        {movingImageData.images && (
          <div className="field">
            <div className="performance-image image inner-post">
              <Carousel>
                {movingImageData.images.map((image) => (
                  <img key={image._key} src={image.url} alt="" className="inner-slide-img" />
                ))}
              </Carousel>
            </div>
          </div>
        )}
        <div></div>
      </div>
    </div>
  )
}

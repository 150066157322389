import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../nav.css'

export default function HomeNav(props) {
  const [isShown, setIsShown] = useState(false)

  const handleClick = (event) => {
    setIsShown((current) => !current)
  }

  const [isMShown, setIsMShown] = useState(false)

  const handleMClick = (event) => {
    setIsMShown((current) => !current)
  }

  // const [isActive, setIsActive] = useState(false);

  // const handleNClick = setIsActive

  // const handleHomeClick = event => {
  //     setIsActive();
  //   };

  return (
    <header>
      <div>
        <div className="home">
          <NavLink to="/" exact>
            APRIL LIN 林森
          </NavLink>
        </div>

        <nav className="nav-circle">
          <div className="introductions nav-item">
            <NavLink to="/introductions">Introductions</NavLink>
          </div>

          <div className="moving-image nav-item">
            <NavLink to="/moving-image">Moving Image</NavLink>
          </div>

          <div className="right-nav">
            <div className="modes nav-item">
              <div className="button-m" onClick={handleMClick}>
                <span className="inline">Modes</span>
              </div>
              {isMShown && (
                <div className="modes-sub">
                  <div className="options">
                    <div>
                      <NavLink to="/modes/digital">Digital,</NavLink>
                    </div>
                    <div>
                      <NavLink to="/modes/performance">Performance,</NavLink>
                    </div>
                    <div>
                      <NavLink to="/modes/writing">Writing</NavLink>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="facilitations nav-item">
              <div className="button-f" onClick={handleClick}>
                <span className="inline">Facilitations</span>
              </div>
              {isShown && (
                <div className="facilitations-sub">
                  <div className="options">
                    <div>
                      <NavLink to="/facilitations/curating">Curating,</NavLink>
                    </div>
                    <div>
                      <NavLink to="/facilitations/programming">Programming,</NavLink>
                    </div>
                    <div>
                      <NavLink to="/facilitations/workshops-talks">Workshops & Talks</NavLink>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../client.js'
import Nav from './Nav'
import BlockContent from '@sanity/block-content-to-react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function MovingImage() {
  const [movingImageData, setMovingImage] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "movingImage" && defined(slug.current)] | order(order){
            title, 
            slug, 
            "images": imagesGallery[]{
              _key,
                "url": asset->.url
              },
            date,
            linkTitle,
            link,
            description,
            year
        }`
      )
      .then((data) => setMovingImage(data))
      .catch(console.error)
  }, [])

  return (
    <main>
      <Nav />
      <section className="moving-outer">
        <div>
          {movingImageData &&
            movingImageData.map((movingImage, index) => (
              <article key={movingImage.slug.current} className="inner-moving-item">
                <div className="moving-background">
                  <img src="https://april-lin.vercel.app/backgrounds/moving-image/Vector6(Stroke).png" alt="" />
                </div>
                <div className="moving-section-text">
                  <div className="field">{movingImage.date}</div>
                  <Link to={'/Moving-Image/' + movingImage.slug.current} key={movingImage.slug.current}>
                    <span key={index}>
                      <h2 className="moving-title titles">{movingImage.title}</h2>
                    </span>
                  </Link>
                  <div className="field">
                    <a href={movingImage.link}>{movingImage.linkTitle}</a>
                  </div>
                  <div className="field">
                    <BlockContent blocks={movingImage.description} projectId="vy80zibe" dataset="production" />
                  </div>
                  <div className="field">{movingImage.year}</div>
                </div>
                {movingImage.images && (
                  <div className="moving-section-image">
                    <div className="field">
                      <Carousel>
                        {movingImage.images.map((image) => (
                          <img key={image._key} src={image.url} alt="" className="inner-slide-img" />
                        ))}
                      </Carousel>
                    </div>
                  </div>
                )}
              </article>
            ))}
        </div>
      </section>
    </main>
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../../client.js'
import Nav from '../Nav'
import BlockContent from '@sanity/block-content-to-react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function WorkshopsTalks() {
  const [workshopsTalksData, setWorkshopsTalks] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "workshopsTalks"] | order(order){
            title, 
            slug, 
            "images": imagesGallery[]{
              _key,
                "url": asset->.url
              },
            date,
            linkTitle,
            link,
            body
        }`
      )
      .then((data) => setWorkshopsTalks(data))
      .catch(console.error)
  }, [])

  return (
    <main>
      <Nav />
      <section>
        <div>
          {' '}
          {workshopsTalksData &&
            workshopsTalksData.map((workshopsTalks, index) => (
              <article className="inner-workshop-item">
                <div className="workshop-section-text">
                  <Link to={'/facilitations/workshops-Talks/' + workshopsTalks.slug.current} key={workshopsTalks.slug.current}>
                    <span key={index}>
                      <h2 className="facilitations-title titles">{workshopsTalks.title}</h2>
                    </span>
                  </Link>
                  <div className="field">{workshopsTalks.date}</div>
                  <div className="field">
                    <a href={workshopsTalks.link}>{workshopsTalks.linkTitle}</a>
                  </div>
                  <div className="workshop-join join">
                    <img src="https://april-lin.vercel.app/joins/facilitations/Vector59.png" alt="" />
                  </div>
                  <div className="field">
                    <div className="workshop-body border">
                      <img src="https://april-lin.vercel.app/borders/facilitations/Vector58.png" alt="" />
                    </div>
                    <div className="workshop-body-text">
                      <BlockContent blocks={workshopsTalks.body} projectId="vy80zibe" dataset="production" />
                    </div>
                  </div>
                </div>
                <div className="workshop-section-image">
                  <div className="workshop-background">
                    <img src="https://april-lin.vercel.app/backgrounds/facilitations/Vector6(Stroke).png" alt="" />
                  </div>
                  {workshopsTalks.images && (
                    <div className="field">
                      {/* <div className="workshop-image image"> */}
                      <Carousel>
                        {workshopsTalks.images.map((image) => (
                          <img key={image._key} src={image.url} alt="" className="inner-slide-img" />
                        ))}
                      </Carousel>
                      {/* </div> */}
                    </div>
                  )}
                </div>
              </article>
            ))}
        </div>
      </section>
    </main>
  )
}

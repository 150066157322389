import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './components/Home'

import CuratingPost from './components/Facilitations/CuratingPost'
import Curating from './components/Facilitations/Curating'
import ProgrammingPost from './components/Facilitations/ProgrammingPost'
import Programming from './components/Facilitations/Programming'
import WorkshopsTalksPost from './components/Facilitations/Workshops-TalksPost'
import WorkshopsTalks from './components/Facilitations/Workshops-Talks'

import DigitalPost from './components/Modes/DigitalPost'
import Digital from './components/Modes/Digital'
import PerformancePost from './components/Modes/PerformancePost'
import Performance from './components/Modes/Performance'
import WritingPost from './components/Modes/WritingPost'
import Writing from './components/Modes/Writing'

import Introductions from './components/Introductions'

import MovingImagePost from './components/Moving-ImagePost'
import MovingImage from './components/Moving-Image'

// import Post from "./components/Post";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/Facilitations/Curating/:slug" element={<CuratingPost />} />
          <Route path="/Facilitations/Curating" element={<Curating />} />

          <Route path="/Facilitations/Programming/:slug" element={<ProgrammingPost />} />
          <Route path="/Facilitations/Programming" element={<Programming />} />

          <Route path="/Facilitations/Workshops-Talks/:slug" element={<WorkshopsTalksPost />} />
          <Route path="/Facilitations/Workshops-Talks" element={<WorkshopsTalks />} />

          <Route path="/Modes/Digital/:slug" element={<DigitalPost />} />
          <Route path="/Modes/Digital" element={<Digital />} />

          <Route path="/Modes/Performance/:slug" element={<PerformancePost />} />
          <Route path="/Modes/Performance" element={<Performance />} />

          <Route path="/Modes/Writing/:slug" element={<WritingPost />} />
          <Route path="/Modes/Writing" element={<Writing />} />

          <Route path="/Introductions" element={<Introductions />} />

          <Route path="/Moving-Image/:slug" element={<MovingImagePost />} />
          <Route path="/Moving-Image" element={<MovingImage />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
